
// @ is an alias to /src
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import { ForgotPasswordRepository, RepositoryFactory } from "@/lib/https";
import { useFieldError, useForm } from "vee-validate";
import router from "@/router";
import { useRoute } from "vue-router";
import { hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";
import errorsText from "@/locales/ja/errors";

export default defineComponent({
  name: "PasswordNew",
  components: {
    FlatButton,
    ValidationPasswordField,
  },

  props: {
    code: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const state = reactive({
      password: "",
      repeatPassword: "",
      code: "",
    });
    const { updateForgotPassword } = updateForgotPasswordMethods();

    const goSuccess = () => {
      router.push({ name: "PasswordNewSuccess" });
    };

    onMounted(() => {
      const route = useRoute();
      state.code = route.query.code as string;
      localStorage.setItem("forgot-password-code", state.code);
    });

    const { submit, errors, values } = formHandler(
      updateForgotPassword,
      goSuccess
    );

    return {
      updateForgotPassword,
      errors,
      submit,
      values,
      ...toRefs(state),
    };
  },
});

const formHandler = (
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const { values, errors, submitForm, setErrors } = useForm();
  // const form = useForm();
  const passwordError = useFieldError("password");
  const repeatPasswordError = useFieldError("repeatPassword");

  const submit = () => {
    submitForm()
      .then(async () => {
        //validate password
        if (
          !hasAlphabet(values.password) ||
          !hasNumber(values.password) ||
          values.password.length < 8
        ) {
          setErrors({
            password: errorsText.invalidPassword,
          });
          return;
        }

        if (values.password != values.repeatPassword) {
          setErrors({
            repeatPassword: errorsText.passwordDosentMacth,
          });
          return;
        }

        if (errors.value["password"] || errors.value["repeatPassword"]) return;

        const formData = new FormData();

        formData.append("password", values["password"] ?? "");
        formData.append("repeatPassword", values["repeatPassword"] ?? "");
        formData.append(
          "code",
          localStorage.getItem("forgot-password-code") ?? ""
        );
        if (await onSubmit(formData)) {
          onSuccess();
        }
      })
      .catch((e) => {
        setErrors({
          password: e.data.message,
        });
      });
  };

  return { submit, errors, passwordError, repeatPasswordError, values };
};

//Create method from API
const updateForgotPasswordMethods = () => {
  const { updateForgotPassword } =
    RepositoryFactory.getRepository<ForgotPasswordRepository>(
      ForgotPasswordRepository
    );

  return {
    updateForgotPassword,
  };
};
